<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <b-navbar
      :type="navbarBackground"
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center"
      :class="[navbarTypeClass]"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackground="navbarBackground"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ Navbar -->


    <!-- Vertical Nav Menu -->
    <div
      class="main-menu menu-fixed menu-accordion menu-shadow"
      :class="[
        { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
        skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
      ]"
      @mouseenter="updateMouseHovered(true)"
      @mouseleave="updateMouseHovered(false)"
    >
      <!-- main menu header-->
      <div class="navbar-header expanded">
        <slot
          name="header"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :toggleCollapsed="toggleCollapsed"
          :collapseTogglerIcon="collapseTogglerIcon"
        >
          <ul class="nav navbar-nav flex-row">

            <!-- Logo & Text -->
            <li class="nav-item mr-auto">
              <b-link
                class="navbar-brand"
                to="/"
              >
                <span
                  v-if="!isVerticalMenuCollapsed"
                  class="brand-logo"
                >
                  <b-img
                    :src="appLogoImage"
                    alt="logo"
                  />
                </span>
                <span
                  v-if="isVerticalMenuCollapsed"
                  class="brand-logo"
                >
                  <b-img
                    :src="appLogoImageSmall"
                    alt="logo"
                    style="width:28px"
                  />
                </span>
              </b-link>
            </li>

            <!-- Toggler Button -->
            <li class="nav-item nav-toggle">
              <b-link class="nav-link modern-nav-toggle">
                <feather-icon
                  icon="XIcon"
                  size="20"
                  class="d-block d-xl-none"
                  @click="toggleVerticalMenuActive"
                />
                <feather-icon
                  :icon="collapseTogglerIconFeather"
                  size="20"
                  class="d-none d-xl-block collapse-toggle-icon"
                  @click="toggleCollapsed"
                />
              </b-link>
            </li>
          </ul>
        </slot>
      </div>
      <!-- / main menu header-->

      <!-- Shadow -->
      <div
        :class="{'d-block': shallShadowBottom}"
        class="shadow-bottom"
      />

      <!-- main menu content-->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
      >
        <ul class="navigation navigation-main">
          <li
            v-for="item in navMenuItems"
            :key="item.header || item.title"
            :item="item"
            class="nav-item mb-1"
          >
            <b-link
              class="d-flex align-items-center py-0 px-0"
              style="margin-bottom: 7px;"
            >
              <feather-icon :icon="item.icon || ''" />
              <span class="menu-title text-wrap font-weight-bold font-medium-2"
                    v-bind:style="{ color: item.color ? item.color : '#625f6e', fontWeight: item.bold ? 'bold' : 'normal'}">
                {{ item.title }}
              </span>
            </b-link>
            <div
              v-for="lesson in item.lessons"
              :key="lesson.id"
              :item="lesson"
              class="pl-2 pt-0 pb-0 d-flex flex-row bd-highlight justify-content-between mb-0 lesson-item"
              @click="showLesson(lesson)"
              style="margin-bottom: 5px !important;"
            >
              <b-link
                class="d-flex align-items-center py-0 px-0 "
                style="margin-bottom: 7px;"
              >
                <span class="menu-title text-wrap font-medium-1"
                      v-bind:style="{ color: lesson.color ? lesson.color : '#625f6e', fontWeight: lesson.bold ? 'bold' : 'normal'}">
                  {{ lesson.title }}
                </span>
              </b-link>
              <div @click="toggleDone(lesson)"
                   :title="lesson.user_done ? 'Lekcja ukończona' : 'Lekcja nie ukończona'"
                   class="d-flex ml-auto align-items-center"
                   style="width: 24px; flex-shrink: 0; cursor: pointer; margin-right: 7px;"
              >
                <feather-icon v-if="!lesson.user_done" icon="CircleIcon" size="24"/>
                <feather-icon v-if="lesson.user_done" icon="CheckCircleIcon" size="24"/>
              </div>
              <b-dropdown
                size="xs"
                variant="link"
                toggle-class="text-decoration-none p-0"
                no-caret
                :right="true"
                style="width: 24px; flex-shrink: 0; margin-right: 7px;"
                v-if="lesson.show_levels"
              >
                <template
                  #button-content
                  class="p-0"
                >
                  <svg
                    v-if="!lesson.user_status"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-more-vertical"
                  ><circle
                    cx="12"
                    cy="12"
                    r="1"
                  /><circle
                    cx="12"
                    cy="5"
                    r="1"
                  /><circle
                    cx="12"
                    cy="19"
                    r="1"
                  /></svg>
                  <img
                    v-else-if="lesson.user_status === 'umiem'"
                    :src="require('@/assets/images/icons/umiem.png')"
                    title="Umiem"
                    alt="Umiem"
                    width="24"
                  >
                  <img
                    v-else-if="lesson.user_status === 'nie-umiem'"
                    :src="require('@/assets/images/icons/nie-umiem.png')"
                    title="Nie umiem"
                    alt="Nie umiem"
                    width="24"
                  >
                  <img
                    v-else-if="lesson.user_status === 'do-powtorzenia'"
                    :src="require('@/assets/images/icons/do-powtorzenia.png')"
                    title="Wrócę do tego"
                    alt="Wrócę do tego"
                    width="24"
                  >
                </template>
                <b-dropdown-item @click="storeStatus(lesson, 'umiem')">
                  <img
                    :src="require('@/assets/images/icons/umiem.png')"
                    title="Umiem"
                    alt="Umiem"
                    width="32"
                  >
                </b-dropdown-item>
                <b-dropdown-item @click="storeStatus(lesson, 'nie-umiem')">
                  <img
                    :src="require('@/assets/images/icons/nie-umiem.png')"
                    title="Nie umiem"
                    alt="Nie umiem"
                    width="32"
                  >
                </b-dropdown-item>
                <b-dropdown-item @click="storeStatus(lesson, 'do-powtorzenia')">
                  <img
                    :src="require('@/assets/images/icons/do-powtorzenia.png')"
                    title="Wrócę do tego"
                    alt="Wrócę do tego"
                    width="32"
                  >
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </li>
        </ul>
      </vue-perfect-scrollbar>
      <!-- /main menu content-->
    </div>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->

    <slot name="customizer" />
  </div>
</template>

<script>
import {
  onUnmounted, provide, computed, ref,
} from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BNavbar, BLink, BImg, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import LayoutContentRendererDefault
  from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached
  from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import mixinVerticalLayout from '@core/layouts/layout-vertical/mixinVerticalLayout'
// ---
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VerticalNavMenuItems
  from '@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import { $themeConfig } from '@themeConfig'
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import VerticalNavMenuHeader
  from '@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-header'
import VerticalNavMenuLink
  from '@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup
  from '@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-group/VerticalNavMenuGroup.vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BLink,
    BImg,
    // AppBreadcrumb,
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [mixinVerticalLayout],
  props: {},
  data() {
    return {
      // navMenuItems: [],
    }
  },
  computed: {
    navMenuItems() {
      return this.$store.getters['app/getCustomMenuLayout'] ?? []
    },
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
    isSmallLogo() {
      return this.$store.getters['verticalMenu/isCollapsed']
    },
  },
  created() {
    console.log('x', this.$store.getters['app/getCustomMenuLayout'] ?? [])
    console.log('isCollapsed', this.isSmallLogo())
  },

  methods: {
    showLesson(lesson) {
      this.$store.commit('app/UPDATE_CURRENT_COURSE', lesson)
      this.isVerticalMenuActive = false;
    },
    storeStatus(lesson, status) {
      const data = {
        lesson_id: lesson.id,
        status,
      }

      this.$http.post(`${this.$store.state.apiDomain}/api/lesson/set-lesson-status`, data).then(response => {
        lesson.user_status = status;
      })
    },
    toggleDone(lesson) {
      const data = {
        lesson_id: lesson.id,
        is_done: !lesson.user_done,
      }

      this.$http.post(`${this.$store.state.apiDomain}/api/lesson/toggle-lesson-done`, data).then(response => {
        lesson.user_done = !lesson.user_done;
      })
    },
  },
  setup(props) {
    const {
      routerTransition, navbarBackground, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { appName, appLogoImage, appLogoImageSmall } = $themeConfig.app
    const { skin } = useAppConfig()
    const shallShadowBottom = ref(false)
    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      // isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,
      collapseTogglerIcon,

      // App Config
      routerTransition,
      navbarBackground,
      navbarBackgroundColor,
      isNavMenuHidden,

      collapseTogglerIconFeather,
      perfectScrollbarSettings,

      // Shadow Bottom
      shallShadowBottom,

      resolveNavItemComponent,

      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      skin,
      appName,
      appLogoImage,
      appLogoImageSmall,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
.lesson-item{
  padding-right: 10px;
}
.lesson-item .dropdown-item{
    padding: 5px;
}
body.dark-layout .main-menu-content .navigation-main li a span{
  color: #d0d2d6 !important;
}
</style>
